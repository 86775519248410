import { computed } from "vue";
import {
  useMutation,
  useQueryClient,
  useInfiniteQuery,
  useQuery
} from "@tanstack/vue-query";

import { useToast } from "primevue/usetoast";
import { useI18n } from "vue-i18n";

import Server from "@/services/Server";

export function useDefaultQuery(endpoint, payload, options = {}) {
  const queryClient = new useQueryClient();
  // options - enabled, getterKey, dependentKey, placeholderData, previousDataAsPlaceholder, infiniteQuery, prefetchQuery
  const queryFunction = computed(() => {
    return options.infiniteQuery ? useInfiniteQuery : options.prefetchQuery ? queryClient.prefetchQuery : useQuery;
  });
  // using post as an option for large request urls - https://github.com/Project-Wayfinder/Wayfinder-Backend/pull/2006
  const serverMethod = options.post ? options.usePatchOverride ? "patch" : "post" : options.infiniteQuery ? "getWithPagination" : "get";
  const getEndpoint = () => options.dependentKey ? endpoint(options.dependentKey) : endpoint;
  const getParams = (pageParam) => {
    const params = {
      ...(payload?.value || payload)
    };
    if (options.infiniteQuery && !params.page) {
      params.page = pageParam;
    }
    return params;
  };

  return queryFunction.value({
    queryKey: [options.baseKey, options.dependentKey, endpoint, payload],
    queryFn: async({ pageParam }) => {
      try {
        return await Server[serverMethod](getEndpoint(), getParams(pageParam));
      }
      catch (error) {
        console.error(error);
      }
    },
    select: (response) => {
      if (options.infiniteQuery) {
        let data = response.pages.map(d => d.data).flatMap(x => x);
        if (options.getterKey) {
          data = options.getterKey(data[0]);
        }
        return {
          data: data,
          pagination: response.pages[response.pages.length - 1]?.pagination
        };
      }
      else if (typeof options.getterKey === "function") {
        return options.getterKey(response);
      }
      else if (typeof options.getterKey === "string") {
        return response[options.getterKey];
      }
      return response;
    },
    initialPageParam: 1,
    getNextPageParam: (lastPage) => {
      if (lastPage && lastPage.pagination.currentPage < lastPage.pagination.totalPages) {
        return lastPage.pagination.currentPage + 1;
      }
    },
    enabled: options.enabled ? options.enabled(payload) : true,
    placeholderData: (previousData) => options.previousDataAsPlaceholder ? previousData : options.placeholderData,
    refetchOnWindowFocus: false,
    keepPreviousData: true,
    staleTime: 20000, // 2 min
    ...(options.config || {})
  });
}

export const useDefaultMutation = (method, endpointFn, options) => {
  // options - invalidationKeys, mutationConfig
  const toast = useToast();
  const { t } = useI18n();
  const queryClient = new useQueryClient();

  const mutation = useMutation({
    mutationFn: async({ payload, urlQuery }) => {
      try {
        const endpoint = endpointFn(urlQuery);
        return await Server[method](endpoint, {
          ...payload,
          ...options.defaultPayload
        });
      }
      catch (error) {
        toast.add({
          severity: "error",
          summary: t("error"),
          detail: t("error_messages.oops"),
          life: 5000,
          group: "main"
        });
      }
    },
    onMutate: async(query) => {
      if (options.invalidationKeys) {
        const keys = options.invalidationKeys(query);
        keys.forEach(async(key) => await queryClient.cancelQueries({ queryKey: key}));
      }

      function updater(oldData) {
        if (!options.mutationConfig) {
          return;
        }
        const id = options.mutationConfig.id(query);
        // paginated
        if (oldData?.pages) {
          const newData = oldData?.pages.map(page => {
            return {
              data: page?.data.map(item => {
                if (item.id === id) {
                  return {
                    ...item,
                    ...options.mutationConfig.field
                  };
                }
                else {
                  return item;
                }
              }),
              pagination: page?.pagination
            };
          });
          return {
            ...oldData,
            pages: newData
          };
        }
        // single object
        else if (oldData?.id) {
          return {
            ...oldData,
            ...options.mutationConfig.field
          };
        }
      }

      if (options.invalidationKeys) {
        const keys = options.invalidationKeys(query);
        keys.forEach(key => queryClient.setQueriesData(key, options.updater || updater));
      }
    },
    onError: (error, item, context) => {
      // handle error here
      console.error(error);
    },
    onSuccess: (data, query) => {
      // revalidate cache here
      if (options.invalidateQueries) {
        const queries = options.invalidateQueries(query);
        queries.forEach(key => queryClient.invalidateQueries({ queryKey: [key] }));
      }
    }
  });

  return {
    mutation
  };
};

// NOTE: using this is a workaround until we refactor the rest of vuex to using tanstack
export function useCacheMutations() {
  const queryClient = new useQueryClient();
  const clearCache = useMutation({
    mutationFn: (keys) => {
      return keys;
    },
    onSuccess: (keys) => {
      keys.forEach(key => queryClient.invalidateQueries({ queryKey: [key] }));
    }
  });

  return {
    clearCache
  };
}
