import {
  nextTick
} from "vue";
import store from "@/store";
import {
  createRouter,
  createWebHistory
} from "vue-router";

const routes = [
  {
    name: "Home",
    path: "/",
    redirect: "/home"
  },
  {
    name: "UserInvitation",
    path: "/invitation/:token",
    meta: {
      protected: false,
      title: "Invitation"
    },
    component: () => import("@/views/UserInvitation.vue")
  },
  {
    name: "ClassroomInvitation",
    path: "/classroom-invitation/",
    meta: {
      qrcode: true,
      protected: true,
      title: "Classroom Invitation",
      users: [
        "Student"
      ]
    },
    component: () => import("@/views/ClassroomInvitation.vue")
  },
  {
    name: "UserLogin",
    path: "/login",
    meta: {
      protected: false,
      title: "Login"
    },
    component: () => import("@/views/UserLogin.vue")
  },
  {
    name: "UserLogout",
    path: "/logout",
    meta: {
      protected: false,
      title: "Logout"
    },
    component: () => import("@/views/UserLogout.vue")
  },
  {
    name: "UserPassword",
    path: "/password/:token",
    meta: {
      protected: false,
      title: "Password"
    },
    component: () => import("@/views/UserPassword")
  },
  {
    name: "HomeScreen",
    path: "/home",
    meta: {
      protected: true,
      title: "Home",
      users: [
        "Manager", "Admin", "Staff", "Developer", "ContentEditor", "Principal", "Assistant", "Counselor", "Teacher", "Student", "Translator"
      ]
    },
    component: () => import("@/views/HomeScreen.vue")
  },
  {
    name: "SystemAdmin",
    path: "/administration",
    meta: {
      protected: true,
      title: "System Admin",
      users: [
        "Manager"
      ]
    },
    component: () => import("@/views/SystemAdmin.vue")
  },
  {
    name: "ClassroomDetails",
    path: "/classes/:classroom",
    meta: {
      protected: true,
      title: "Class",
      users: [
        "Manager", "Principal", "Assistant", "Counselor", "Teacher"
      ]
    },
    component: () => import("@/views/ClassroomDetails.vue")
  },
  {
    name: "ClassroomList",
    path: "/classes",
    meta: {
      protected: true,
      title: "Classes",
      users: [
        "Principal", "Assistant", "Counselor", "Teacher"
      ]
    },
    component: () => import("@/views/ClassroomList.vue")
  },
  {
    name: "CollectionList",
    path: "/collections",
    meta: {
      protected: true,
      title: "Collections",
      users: [
        "Manager", "Principal", "Assistant", "Counselor", "Teacher"
      ]
    },
    component: () => import("@/views/CollectionList.vue")
  },
  {
    name: "CollectionDetails",
    path: "/collections/:collection",
    meta: {
      protected: true,
      title: "Collection",
      users: [
        "Manager", "Principal", "Assistant", "Counselor", "Teacher"
      ]
    },
    component: () => import("@/views/CollectionDetails.vue")
  },
  {
    name: "UserDirectory",
    path: "/directory",
    meta: {
      protected: true,
      title: "Directory",
      users: [
        "Principal", "Assistant", "Counselor", "Teacher"
      ]
    },
    component: () => import("@/views/UserDirectory.vue")
  },
  {
    name: "StudentDetails",
    path: "/students/:student",
    meta: {
      protected: true,
      title: "Student",
      users: [
        "Manager", "Principal", "Assistant", "Counselor", "Teacher"
      ]
    },
    component: () => import("@/views/StudentDetails.vue")
  },
  {
    name: "LessonDetails",
    path: "/curriculum/:toolkit/:lesson",
    props: true,
    meta: {
      protected: true,
      title: "Lesson",
      users: [
        "Manager", "Principal", "Assistant", "Counselor", "Teacher", "Translator"
      ]
    },
    component: () => import("@/views/LessonDetails.vue")
  },
  {
    name: "TeacherLessonDetails",
    path: "/resource/teacher-toolkit/:toolkit/:lesson",
    props: true,
    meta: {
      protected: true,
      title: "Lesson",
      users: [
        "Manager", "Principal", "Assistant", "Counselor", "Teacher", "Translator"
      ]
    },
    component: () => import("@/views/LessonDetails.vue")
  },
  {
    name: "ToolkitDetails",
    path: "/curriculum/:toolkit",
    props: true,
    meta: {
      protected: true,
      title: "Curriculum",
      users: [
        "Manager", "Principal", "Assistant", "Counselor", "Teacher", "Translator"
      ]
    },
    component: () => import("@/views/ToolkitDetails.vue")
  },
  {
    name: "ToolkitList",
    path: "/curriculum",
    meta: {
      protected: true,
      title: "Curriculums",
      users: [
        "Manager", "Principal", "Assistant", "Counselor", "Teacher", "Student", "Translator"
      ]
    },
    component: () => import("@/views/ToolkitList.vue")
  },
  {
    name: "AssistantDetails",
    path: "/administrators/:assistant",
    meta: {
      protected: true,
      title: "Administrator",
      users: [
        "Principal", "Assistant", "Counselor"
      ]
    },
    component: () => import("@/views/AssistantDetails.vue")
  },
  {
    name: "AssistantList",
    path: "/administrators",
    meta: {
      protected: true,
      title: "Administrators",
      users: [
        "Principal", "Assistant", "Counselor"
      ]
    },
    component: () => import("@/views/AssistantList.vue")
  },
  {
    name: "TeacherDetails",
    path: "/teachers/:teacher",
    props: true,
    meta: {
      protected: true,
      title: "Teacher",
      users: [
        "Manager", "Principal", "Assistant", "Counselor", "Teacher"
      ]
    },
    component: () => import("@/views/TeacherDetails.vue")
  },
  {
    name: "TeacherList",
    path: "/teachers",
    meta: {
      protected: true,
      title: "Teachers",
      users: [
        "Principal", "Assistant", "Counselor", "Teacher"
      ]
    },
    component: () => import("@/views/TeacherList.vue")
  },
  {
    name: "WaypointDetails",
    path: "/waypoints/formative",
    meta: {
      protected: true,
      title: "Waypoint",
      users: [
        "Manager", "Principal", "Assistant", "Counselor", "Teacher"
      ]
    },
    component: () => import("@/views/WaypointDetails.vue")
  },
  {
    name: "WaypointList",
    path: "/waypoints",
    meta: {
      protected: true,
      title: "Waypoints",
      users: [
        "Manager", "Principal", "Assistant", "Counselor", "Teacher"
      ]
    },
    component: () => import("@/views/WaypointList.vue")
  },
  {
    name: "FormativeForm",
    path: "/waypoints/create-formative",
    meta: {
      protected: true,
      title: "Waypoints Formative Form",
      users: [
        "Principal", "Assistant", "Counselor"
      ]
    },
    component: () => import("@/views/FormativeForm.vue")
  },
  {
    name: "SummativeStudentForm",
    path: "/waypoints/summative",
    meta: {
      protected: true,
      title: "Summative Waypoint Form",
      users: [
        "Manager", "Principal", "Assistant", "Counselor", "Teacher", "Student"
      ]
    },
    component: () => import("@/views/SummativeStudentForm.vue")
  },
  {
    name: "TeacherResourceDetails",
    path: "/resource/:resource",
    meta: {
      protected: true,
      title: "Support Resource",
      users: [
        "Manager", "Principal", "Assistant", "Counselor", "Teacher", "Translator"
      ]
    },
    component: () => import("@/views/TeacherResourceDetails.vue")
  },
  {
    name: "ResourceList",
    path: "/resource",
    meta: {
      protected: true,
      title: "Support",
      users: [
        "Manager", "Principal", "Assistant", "Counselor", "Teacher", "Translator"
      ]
    },
    component: () => import("@/views/ResourceList.vue")
  },
  {
    name: "LibraryDetails",
    path: "/library/:resource",
    meta: {
      protected: true,
      title: "Library Resource",
      users: [
        "Manager", "Principal", "Assistant", "Counselor", "Teacher", "Translator", "Student"
      ]
    },
    component: () => import("@/views/LibraryDetails.vue")
  },
  {
    name: "LibraryList",
    path: "/library",
    meta: {
      protected: true,
      title: "Library",
      users: [
        "Manager", "Principal", "Assistant", "Counselor", "Teacher", "Translator"
      ]
    },
    component: () => import("@/views/LibraryList.vue")
  },
  // STUDENT ONLY VIEWS
  {
    name: "StudentToolkitDetails",
    path: "/student/curriculum/:toolkit",
    props: true,
    meta: {
      protected: true,
      title: "Curriculum",
      users: [
        "Student"
      ]
    },
    // component: () => import("@/views/ToolkitDetails.vue")
    component: () => import("@/views/StudentToolkitDetails.vue")
  },
  {
    name: "StudentLibraryList",
    path: "/student_library",
    meta: {
      protected: true,
      title: "For Students",
      users: [
        "Manager", "Principal", "Assistant", "Counselor", "Teacher", "Translator", "Student"
      ]
    },
    component: () => import("@/views/StudentLibraryList.vue")
  },
  {
    name: "LtiAuth",
    path: "/lti-auth",
    meta: {
      protected: false,
      lti: true,
      title: "LtiAuth",
      users: [
        "Manager", "Principal", "Assistant", "Counselor", "Teacher", "Student", "Translator"
      ]
    },
    component: () => import("@/views/LtiAuth.vue")
  },
  {
    name: "LtiList",
    path: "/lti",
    meta: {
      protected: true,
      lti: true,
      title: "Lti",
      users: [
        "Manager", "Principal", "Assistant", "Counselor", "Teacher", "Student", "Translator"
      ]
    },
    component: () => import("@/views/LtiList.vue")
  },
  {
    name: "LtiLessonDetails",
    path: "/lti/curriculum/:toolkit/:lesson",
    props: true,
    meta: {
      protected: true,
      lti: true,
      title: "LtiLesson",
      users: [
        "Manager", "Principal", "Assistant", "Counselor", "Teacher", "Student", "Translator"
      ]
    },
    component: () => import("@/views/LessonDetails.vue")
  },
  {
    name: "LtiWaypoint",
    path: "/lti/waypoints/:waypoint",
    props: true,
    meta: {
      protected: true,
      lti: true,
      title: "LtiWaypoint",
      users: [
        "Manager", "Manager", "Principal", "Assistant", "Counselor", "Teacher", "Student", "Translator"
      ]
    },
    component: () => import("@/views/LtiWaypoint.vue")
  },
  {
    name: "Code",
    path: "/code",
    meta: {
      qrcode: true,
      protected: false,
      title: "Code"
    },
    component: () => import("@/views/Code.vue")
  },
  {
    name: "QrCodePrint",
    path: "/qrcode/:code",
    meta: {
      protected: false,
      title: "Print QR Code"
    },
    component: () => import("@/views/QrCodePrint.vue")
  },
  {
    name: "NotFound",
    path: "/:pathMatch(.*)*",
    meta: {
      protected: false,
      title: "404 - Not Found"
    },
    component: () => import("@/views/NotFound.vue")
  },
  {
    name: "TeacherToolkit",
    path: "/resource/teacher-toolkit/:toolkit",
    meta: {
      protected: true,
      title: "Wayfinder Academy",
      users: [
        "Manager", "Principal", "Assistant", "Counselor", "Teacher", "Translator"
      ]
    },
    component: () => import("@/views/ToolkitDetails.vue")
  },
  {
    name: "FeaturedContentSetUp",
    path: "/featured-content/set-up",
    meta: {
      protected: true,
      title: "Featured Content Set Up",
      users: [
        "Manager", "Principal", "Assistant"
      ]
    },
    component: () => import("@/views/FeaturedContentSetUp.vue")
  },
  {
    name: "FeaturedContentReview",
    path: "/featured-content/review",
    meta: {
      protected: true,
      title: "Featured Content Review",
      users: [
        "Manager", "Principal", "Assistant"
      ]
    },
    component: () => import("@/views/FeaturedContentReview.vue")
  },
  {
    name: "SummativeSetUp",
    path: "/summative/set-up",
    meta: {
      protected: true,
      title: "Summative Set Up",
      users: [
        "Manager"
      ]
    },
    component: () => import("@/views/AssessmentSetUp.vue")
  },
  {
    name: "FormativeSetUp",
    path: "/formative/set-up",
    meta: {
      protected: true,
      title: "Formative Set Up",
      users: [
        "Manager"
      ]
    },
    component: () => import("@/views/AssessmentSetUp.vue")
  },
  {
    name: "WaypointsEducatorObserved",
    path: "/waypoints-educator-observed/:waypoint",
    meta: {
      protected: true,
      title: "Waypoints Educator Observed",
      users: [
        "Counselor", "Teacher"
      ]
    },
    component: () => import("@/views/WaypointsEducatorObserved.vue")
  }
];

const router = createRouter({
  history: createWebHistory(process.env.VUE_APP_BASE_URL),
  routes: routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    }
    else if (to.path === from.path) {
      return {};
    }
    else {
      return {
        top: 0
      };
    }
  }
});

router.onError(error => {
  if (/ChunkLoadError:.*failed./i.test(error.message) || /Error: Loading CSS chunk/i.test(error.message)) {
    window.location.reload();
  }
});

router.beforeEach(async(to, from, next) => {
  // TODO: clean up the logic here
  nextTick(() => {
    document.title = to.meta.title ? `Wayfinder | ${to.meta.title}` : "Wayfinder";
  });
  if (to.meta.protected) {
    let user = await store.dispatch("userRestore");
    if (user && user.email) {
      if (to.meta.users == null || to.meta.users.includes(store.getters.activeRole)) {
        next();
      }
      else {
        next({
          name: "NotFound"
        });
      }
    }
    else {
      next({
        path: "/login",
        query: to?.query
      });
    }
  }
  else {
    next();
  }
});

export default router;
