import Models from "./Models";
import Completion from "./Completion";

export default class Completions extends Models {

  constructor(models = []) {
    super(Completion, models);
  }

  static async clear() {
    return Models._clear("completions");
  }

  static async count(user, toolkit = null, chapter = null, lesson = null, offset = 0, limit = 10) {
    let path = Models.path("completions", user, toolkit, chapter, lesson);
    return Models._count(path, null, offset, limit, "-updated_at", null);
  }

  async storeByClassroom(classroom) {
    for (let completion of this) {
      await completion.storeByClassroom(classroom);
    }
  }

  static async restore(user, classroom = null, toolkit = null, chapter = null, lesson = null, offset = 0, limit = 10) {
    let path = classroom ? `completions/${classroom}/${user}/${toolkit}/${chapter}/${lesson}` : Models.path("completions", user, toolkit, chapter, lesson);
    return Models._restore(Completions, Completion, path, null, offset, limit, "-updated_at", null);
  }

  static async load(user = null, classroom = null, toolkit = null, chapter = null, lesson = null, term, offset = 0, limit = 10) {
    let params = {};
    if (user && user.length > 0) {
      params["user_id"] = user;
    }
    if (classroom && classroom.length > 0) {
      params["classroom_id"] = classroom;
    }
    if (toolkit && toolkit.length > 0) {
      params["toolkit_id"] = toolkit;
    }
    if (chapter && chapter.length > 0) {
      params["chapter_id"] = chapter;
    }
    if (lesson && lesson.length > 0) {
      params["lesson_id"] = lesson;
    }
    if (term && term.length > 0) {
      params["term_id"] = term;
    }
    let query = Object.keys(params).length > 0 ? Object.keys(params).map(key => key + "=" + params[key]).join("&") : "";
    let path = query.length > 0 ? `completions.json?${query}` : "completions.json";
    if (lesson && classroom) {
      path = `/lessons/${params["lesson_id"]}/completions.json?classroom_id=${params["classroom_id"]}`;
    }
    return Models._load(Completions, Completion, path, null, offset, limit, false, false);
  }

  async loadCompletions(params) {
    const path = "/completions.json";
    const response = await this._getWithPagination(path, params);
    return response;
  }
}