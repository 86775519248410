import dayjs from "dayjs";
let isSameOrAfter = require("dayjs/plugin/isSameOrAfter");
let isSameOrBefore = require("dayjs/plugin/isSameOrBefore");
dayjs.extend(isSameOrAfter);
dayjs.extend(isSameOrBefore);

import axios from "axios";

import Analytics from "@/services/Analytics";
import Server from "@/services/Server";

let GRADE_LEVELS = [
  {
    label: "PreK",
    value: "-1",
    checked: false,
    shortLabel: "PreK",
    overrideFontSize: "text-[9px]",
    overrideLabel: "PreK"
  },
  {
    label: "Kindergarten",
    value: "0",
    checked: false,
    shortLabel: "K",
    overrideLabel: "K"
  },
  {
    label: "1st Grade",
    value: "1",
    checked: false,
    shortLabel: "1st",
    overrideLabel: "1"
  },
  {
    label: "2nd Grade",
    value: "2",
    checked: false,
    shortLabel: "2nd",
    overrideLabel: "2"
  },
  {
    label: "3rd Grade",
    value: "3",
    checked: false,
    shortLabel: "3rd",
    overrideLabel: "3"
  },
  {
    label: "4th Grade",
    value: "4",
    checked: false,
    shortLabel: "4th",
    overrideLabel: "4"
  },
  {
    label: "5th Grade",
    value: "5",
    checked: false,
    shortLabel: "5th",
    overrideLabel: "5"
  },
  {
    label: "6th Grade",
    value: "6",
    checked: false,
    shortLabel: "6th",
    overrideLabel: "6"
  },
  {
    label: "7th Grade",
    value: "7",
    checked: false,
    shortLabel: "7th",
    overrideLabel: "7"
  },
  {
    label: "8th Grade",
    value: "8",
    checked: false,
    shortLabel: "8th",
    overrideLabel: "8"
  },
  {
    label: "9th Grade",
    value: "9",
    checked: false,
    shortLabel: "9th",
    overrideLabel: "9"
  },
  {
    label: "10th Grade",
    value: "10",
    checked: false,
    shortLabel: "10th",
    overrideLabel: "10"
  },
  {
    label: "11th Grade",
    value: "11",
    checked: false,
    shortLabel: "11th",
    overrideLabel: "11"
  },
  {
    label: "12th Grade",
    value: "12",
    checked: false,
    shortLabel: "12th",
    overrideLabel: "12"
  }
];

let TIER_LEVELS = [
  {
    label: "Tier 1",
    value: "1"
  },
  {
    label: "Tier 2",
    value: "2"
  },
  {
    label: "Tier 3",
    value: "3"
  }
];

export default {
  data() {
    return {
      userTypeKey: {
        "Assistant": this.$t("user_type_key.assistant"),
        "Manager": this.$t("user_type_key.manager")
      }
    };
  },
  computed: {
    user() {
      return this.$store.getters.getUser();
    },
    is3to5() {
      return this.user?.isStudent() && this.user?.max_grade <= 5;
    },
    isLti() {
      return this.$route.meta.lti;
    }
  },
  methods: {
    isVisible(selector) {
      let element = document.querySelector(selector);
      return element && element.offsetParent != null;
    },
    hasFunction(object, method) {
      return object != null && typeof object[method] == "function";
    },
    getElementById(id) {
      return id && id.length > 0 ? document.getElementById(id) : null;
    },
    formatForId(value) {
      return typeof value === "string" ? value.replace(/[^a-z0-9]/gi, "-").toLowerCase() : "";
    },
    printPage(id, title = "", subtitle = "") {
      let cloneElement = document.getElementById(id).cloneNode(true);
      if (cloneElement) {
        const printButton = cloneElement.querySelector(".print-button");
        if (printButton) {
          printButton.remove();
        }
        const toggleButton = cloneElement.querySelector("#version-toggle");
        if (toggleButton) {
          toggleButton.remove();
        }
        let header = document.getElementsByTagName("head")[0];
        const zenDesk = "<script id=\"ze-snippet\" src=\"https://static.zdassets.com/ekr/snippet.js?key=033fd35c-b47a-4497-b2f9-a7a59066d457\"></script>";
        header.innerHTML = header.innerHTML.replace(zenDesk, "");
        let printer = window.open("", "PRINT", "height=600,width=800");
        printer.document.write("<html style='overflow-y:scroll'>");
        printer.document.write("<head>" + header.innerHTML + "</head>");
        printer.document.write("<body style='overflow-y:scroll; position: static'>");
        printer.document.write("<div>" + cloneElement.innerHTML + "</div>");
        printer.document.write("</body></html>");
        printer.document.close();
        printer.focus();
        setTimeout(() => {
          printer.print();
        }, 1200);
      }
    },
    blurFocus() {
      setTimeout(() => {
        document.activeElement.blur();
      }, 50);
    },
    getCookie(key) {
      return this.$cookie.getCookie(key);
    },
    hasCookie(key) {
      return this.$cookie.isCookieAvailable(key);
    },
    setCookie(key, value) {
      return this.$cookie.setCookie(key, value, {
        expire: -1,
        path: "/",
        domain: "",
        secure: "",
        sameSite: ""
      });
    },
    hasAbility(action, subject) {
      let userAbility = this.$store.getters.getUserAbility(action, subject);
      return userAbility != null && userAbility == true;
    },
    isDistrictRosteringType(method) {
      let user = this.$store.getters.getUser();
      return (user?.district?.rostering_method && user.district.rostering_method === method);
    },
    showEditMenu(classroom) {
      return this.hasAbility("update", "Classroom") && this.classroom?.access_id?.length === 6 && (this.isDistrictRosteringType("hand") || ((this.user?.district?.prefs_custom_classes_allowed === true || this.user.isCounselor()) && classroom?.custom_class === true));
    },
    async storeDispatch(name, params = {}) {
      return await this.$store.dispatch(name, params);
    },
    logPage(user = null, params = {}) {
      Analytics.logPage(this.$route.name, user, params);
    },
    logNamedPage(name = "", user = null, params = {}) {
      Analytics.logPage(name, user, params);
    },
    logModal(user = null, params = {}) {
      Analytics.logPage(this.$options.name, user, params);
    },
    logAction(user = null, params = {}) {
      Analytics.logAction(this.$options.name, user, params);
    },
    showRootPage(name) {
      this.$router.replace(name, () => { });
    },
    showPage(name) {
      this.$router.push(name, () => { });
    },
    hidePage() {
      this.$router.go(-1);
    },
    goBack() {
      this.$router.go(-1);
    },
    openUrl(url) {
      if (url.startsWith("mailto:")) {
        window.open(url, "_blank");
      }
      else if (url.startsWith("tel:")) {
        window.open(url, "_blank");
      }
      else if (url.startsWith("http")) {
        window.open(url, "_blank");
      }
      else {
        window.open("http://" + url, "_blank");
      }
    },
    openUrlSameWindow(url) {
      if (url.startsWith("http")) {
        window.open(url, "_top");
      }
      else {
        window.open("http://" + url, "_top");
      }
    },
    openEmail(email) {
      window.open(`mailto:${email}`, "_blank");
    },
    openPhone(telephone) {
      window.open(`tel:${telephone}`, "_blank");
    },
    async downloadBlobFile(url, name, type = "xlsx") {
      try {
        const data = await Server.get(url, {}, "blob");
        const contentType = type === "pdf" ? "application/pdf" : "application/vnd.ms-excel";
        const blob = new Blob([data], { type: contentType });
        const newUrl = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.style.display = "none";
        a.href = newUrl;
        a.download = `${name.replace(/[^a-z0-9]/gi, "_").toLowerCase()}.${type}`;
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(newUrl);
      }
      catch (error) {
        console.error("Error downloading file:", error);
      }
    },
    errorMessage(error) {
      if (error && error.data && error.data.error) {
        return error.data.error;
      }
      if (error && error.result && error.result.error && error.result.error.message) {
        return error.result.error.message;
      }
      if (error && error.response && error.response.error && error.response.error.message) {
        return error.response.error.message;
      }
      if (error && error.response && error.response.error) {
        return error.response.error;
      }
      if (error && error.response && error.response.data && error.response.data.error && error.response.data.error.message) {
        return error.response.data.error.message;
      }
      if (error && error.response && error.response.data && error.response.data.error) {
        return error.response.data.error;
      }
      if (error && error.error && error.error.message) {
        return error.error.message;
      }
      if (error && error.message) {
        return error.message;
      }
      if (error && error.error) {
        return error.error;
      }
      return JSON.stringify(error);
    },
    async downloadCsvFile(url, name) {
      try {
        const response = await axios.get(url, {
          responseType: "blob"
        });
        const blob = new Blob([
          response.data
        ], {
          type: response.headers["content-type"]
        });
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = name;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
      catch (error) {
        this.showToastNew("error", this.$t("data_export.download_error"));
      }
    },
    translate(message, params = {}) {
      try {
        if (typeof this.$t === "function") {
          return this.$t(message, params || {});
        }
        return message;
      }
      catch (error) {
        return message;
      }
    },
    async showLoading(message) {
      this.$store.dispatch("setAppLoading", this.translate(message || "helpers.loading"));
    },
    async hideLoading(delay = 200) {
      setTimeout(async() => {
        this.$store.dispatch("setAppLoading", false);
      }, delay);
    },
    async showConfirm(title, message = null, callback, acceptClass = "", rejectClass = "") {
      this.$confirm.require({
        header: this.translate(title),
        message: this.translate(message),
        accept: callback,
        acceptClass,
        rejectClass
      });
    },
    openDialog(component, size = "medium", onClose = () => { }, options = {}) {
      const styles = {
        medium: {
          borderRadius: "16px",
          maxWidth: "780px",
          maxHeight: "calc(100vh - 128px)",
          width: "100%",
          padding: "0px"
        },
        large: {
          borderRadius: "16px",
          maxWidth: "1200px",
          maxHeight: "calc(100vh - 128px)",
          width: "100%",
          minHeight: "360px"
        },
        small: {
          borderRadius: "16px",
          maxWidth: "340px",
          padding: "40px"
        }
      };

      return this.$dialog.open(component, {
        props: {
          draggable: false,
          dismissableMask: true,
          modal: true,
          show: this.blurFocus(),
          showHeader: false,
          style: styles[size]
        },
        onClose: (options) => typeof onClose === "function" ? onClose(options) : null,
        ...options
      });
    },
    async showError(title, error) {
      this.$toast.add({
        severity: "error",
        summary: title,
        detail: error,
        life: 5000,
        group: "main"
      });
    },
    // TODO: simplify/refactor toast calls
    // type - success, warning, error, info
    async showToastNew(type = "success", detail, life = 5000, summary = null) {
      this.$toast.add({
        severity: type,
        summary: summary || this.$t(type),
        detail: detail,
        life: life,
        group: "main"
      });
    },
    async showSuccessToast(detail) {
      this.$toast.add({
        severity: "success",
        summary: this.$t("success"),
        detail: this.$t(detail),
        life: 5000,
        group: "main"
      });
    },
    async showWarning(error, stringify = true) {
      if (error.response && error.response.status == 403) {
        this.showError(this.$t("helpers.not_authorized"), this.$t("helpers.not_authorized_description"));
      }
      else {
        await this.showToastNew("error", stringify ? this.errorMessage(error) : error);
      }
    },
    async showWarningToast(title, detail) {
      this.$toast.add({
        severity: "warn",
        summary: this.translate(title),
        detail: this.translate(detail),
        life: 5000,
        group: "main"
      });
    },
    setFocus(element) {
      if (element && element.$el) {
        if (this.hasFunction(element.$el, "setFocus")) {
          element.$el.setFocus();
        }
        else {
          element.$el.scrollIntoView(false);
        }
      }
      else if (element) {
        if (this.hasFunction(element, "setFocus")) {
          element.setFocus();
        }
        else if (this.hasFunction(element, "scrollIntoView")) {
          element?.scrollIntoView(false);
        }
      }
    },
    showUserLogin(email = null, code = null, create = false) {
      let query = "";
      if (email) {
        query = `email=${email}`;
      }
      if (code) {
        query += query ? `&c=${code}` : `c=${code}`;
      }
      if (create) {
        query += query ? "&create=1" : "create=1";
      }
      window.location.href = `/login${query ? `?${query}` : ""}`;
    },
    showHomeScreen(classInvitation = false, addedToClassroom = false, classroomName = null) {
      let options = {
        name: "HomeScreen"
      };
      if (classInvitation) {
        options.query = {
          invited: true
        };
      }
      if (addedToClassroom) {
        options.query = {
          added_to_classroom: true,
          classroom_name: classroomName
        };
      }
      this.showRootPage(options);
    },
    showToolkitDetails(toolkit) {
      this.showPage({
        name: this.user.isStudent() ? "StudentToolkitDetails" : "ToolkitDetails",
        params: {
          toolkit: this.getModelId(toolkit)
        }
      });
    },
    showLessonDetails(toolkit, lesson, hash = null, lti = false, teacherToolkit = false) {
      this.showPage({
        name: teacherToolkit ? "TeacherLessonDetails" : lti ? "LtiLessonDetails" : "LessonDetails",
        params: {
          toolkit: this.getModelId(toolkit),
          lesson: this.getModelId(lesson)
        },
        query: lti ? this.$route.query : null,
        hash: hash ? `#${hash}` : null
      });
    },
    showAssignmentDetails(assignment) {
      const params = {
        toolkit: assignment.toolkit_id,
        lesson: assignment.lesson_id
      };
      if (assignment.type === "ResourceAssignment") {
        params.resource = assignment.resource_id;
      }

      this.showPage({
        name: assignment.type === "ResourceAssignment" ? "LibraryDetails" : this.user.isStudent() ? "StudentToolkitDetails" : "LessonDetails",
        params,
        query: this.user.isStudent() ? {
          lesson: assignment.lesson_id
        } : {}
      });
    },
    showClassroomList() {
      this.showRootPage({
        name: "ClassroomList"
      });
    },
    showClassroomDetails(classroom, welcome = null) {
      this.showPage({
        name: "ClassroomDetails",
        params: {
          classroom: this.getModelId(classroom)
        },
        query: welcome ? {
          welcome
        } : null
      });
    },
    showStudentDetails(student, query) {
      this.showPage({
        name: "StudentDetails",
        params: {
          student: this.getModelId(student)
        },
        query
      });
    },
    showWaypointDetails(waypoint, query = {}) {
      this.showPage({
        name: "WaypointDetails",
        query: {
          waypoint: this.getModelId(waypoint),
          ...query
        }
      });
    },
    showAssistantDetails(assistant) {
      this.showPage({
        name: "AssistantDetails",
        params: {
          assistant: this.getModelId(assistant)
        }
      });
    },
    showTeacherDetails(teacher) {
      this.showPage({
        name: "TeacherDetails",
        params: {
          teacher: this.getModelId(teacher)
        }
      });
    },
    showCollectionList() {
      this.showRootPage({
        name: "CollectionList"
      });
    },
    showCollectionDetails(collection) {
      this.showPage({
        name: "CollectionDetails",
        params: {
          collection: this.getModelId(collection)
        }
      });
    },
    showQRCodePrint(code, download = false) {
      this.showPage({
        name: "QrCodePrint",
        params: {
          code
        },
        query: {
          download
        }
      });
    },
    getModelId(model, fallback = null) {
      if (model && model.hasOwnProperty("id") && model.id && model.id.length > 0) {
        return model.id;
      }
      if (model && model.length > 0) {
        return model;
      }
      return fallback;
    },
    groupBy(array, attribute) {
      if (array && array.length > 0) {
        return array.reduce((items, item) => {
          let value = item[attribute];
          if (!items[value]) {
            items[value] = [];
          }
          items[value].push(item);
          return items;
        }, {});
      }
      return {};
    },
    getGradeLevels() {
      return GRADE_LEVELS;
    },
    getGradeLevel(grade_level_key) {
      let gradeLevels = this.getGradeLevels();
      let gradeLevel = gradeLevels.find(x => String(x.value) === String(grade_level_key));
      return gradeLevel;
    },
    getStudentGradeLevels() {
      let gradeLevels = this.getGradeLevels();
      return gradeLevels.slice(4, 14);
    },
    getTierLevels() {
      return TIER_LEVELS;
    },
    getToolkitColor(toolkit) {
      const colorKey = {
        "19bed49a-d136-4efb-92ce-53a32e0d1a1c": "blue", // K
        "6b83f072-c109-4d8c-9524-404ae7c211da": "dark-green", // 1st
        "9954b9cc-f08c-4e16-bf15-ba7d89df5d7c": "red", // 2nd
        "e7364678-bffd-4c89-865a-7b616fba897b": "blue", // 3rd
        "e47a5749-812d-4bbc-bbc5-1e0468a7d8b1": "yellow", // 4th
        "7806f2d3-8030-4f20-8305-5d023333e439": "purple", // 5th
        "59f526c7-8436-46ed-92cd-a26205334578": "green-70", // 6th
        "f21298e7-df5c-4ca3-8853-69f8edebbc06": "yellow", // 7th
        "b659902b-02cd-46b5-bb4b-7721ff1940e8": "green", // 8th
        "16920771-a89f-428c-9130-e8b461a29f47": "red", // 9th
        "2866bd38-ee9e-445a-80d9-b38ce3d37b05": "blue", // 10th
        "b5724a55-e759-40b0-b637-2342dcd2cad2": "dark-green", // 11th
        "27468f4c-377f-49fd-bc6d-1a9048f9245c": "dark-purple" // 12th
      };
      return colorKey[toolkit] || "dark-purple";
    },
    getToolkitBackground(toolkit) {
      if (toolkit?.image || toolkit?.toolkit_image) {
        return toolkit.image || toolkit.toolkit_image;
      }
    },
    round(number, precision = 2) {
      return Math.round((number + Number.EPSILON) * (10 ** precision)) / (10 ** precision);
    },
    getRandomNumberBetween(min, max) {
      return Math.floor(Math.random() * (max - min + 1) + min);
    },
    dateFormat(value, format = "M/D/YY") {
      return dayjs(value).format(format);
    },
    dateIsWithin(startA, endA, startB, endB, unit = "day") {
      return dayjs(startA).isSameOrAfter(startB, unit) && dayjs(endA).isSameOrBefore(endB, unit);
    },
    getDateString(date) {
      if (date) {
        return new Date(date.replace(/-/g, "\/").replace(/T.+/, ""));
      }
      return null;
    },
    // needed a way to get filter for lesson guide and slide deck with the lack of consistency on the title field
    lessonGuideMatch(list, type) {
      const key = {
        "Lesson Guide": {
          includes: [
            "Lesson Guide", "In-Person"
          ],
          excludes: [
            "Virtual"
          ]
        },
        "Slide Deck": {
          includes: [
            "Slide Deck"
          ]
        }
      }[type];
      return list?.find(r => key.includes.some(str => r.title.includes(str) || r.name.includes(str)) && (!key.excludes || key.excludes.every(str => r.title.includes(str) === false || r.name.includes(str) === false)));
    },
    copy(url) {
      navigator.clipboard.writeText(url);
    }
  },
  watch: {
    "$route"() {
      // closes any open dialogs when route changes
      if (this.dialogRef && Object.keys(this.dialogRef)?.length > 0) {
        this.dialogRef.close();
      }
    }
  }
};
