<!-- TODO: this has since been changed - need to update this documentation -->
<!-- Form Dialog Style - https://www.figma.com/file/luxQ2YUVmYts15VFc8fO4E/2024-Component-Library?node-id=1544%3A8523&mode=dev -->
<!-- Content Dialog Style - https://www.figma.com/file/luxQ2YUVmYts15VFc8fO4E/2024-Component-Library?node-id=2458%3A2140&mode=dev -->
<!-- DynamicDialog is set in App.vue with a basic pt config, the root styles that change depending on which type of dialog style is set in the the openDialog Helper Function -->
<template>
  <div class="@container">
    <!-- header override -->
    <slot
      v-if="$slots['header-override']"
      name="header-override" />
    <!-- header -->
    <div
      v-else
      data-testid="dialog-header"
      class="z-10 flex h-[80px] w-full shrink-0 items-center justify-between gap-20 bg-white py-24"
      :class="{'px-40' : modal}">
      <div
        data-testid="header-label"
        class="text-26 font-bold leading-snug text-black">
        <slot
          v-if="$slots.title"
          name="title"></slot>
        <span v-else>
          {{ title }}
        </span>
      </div>
      <div
        :class="{'w-full': !title}"
        data-testid="header-actions">
        <div class="flex items-center gap-20">
          <slot name="actions" />
          <div
            v-if="dialogRef && Object.keys(dialogRef).length > 0"
            class="ml-auto">
            <i
              class="fa-solid fa-xmark cursor-pointer text-[28px] text-grey-3 hover:opacity-80"
              aria-label="Close"
              data-testid="close-dialog"
              @click="dialogRef.close()"
              @keydown.enter="dialogRef.close()" />
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- body -->
  <div
    class="relative flex-1 overflow-auto"
    :class="{'px-40 pb-40' : modal}">
    <!-- subheading -->
    <div
      v-if="subheading"
      class="pb-40"
      data-testid="sub-heading">
      {{ subheading }}
    </div>
    <!-- content -->
    <div data-testid="dialog-content">
      <slot name="content" />
    </div>
  </div>
  <!-- footer -->
  <div
    v-if="$slots.footer || $slots.cancel"
    class="sticky bottom-0 flex w-full justify-between rounded-b-16 border-t border-grey-4 bg-white py-24"
    :class="{'px-40' : modal}"
    data-testid="dialog-footer">
    <div class="flex gap-12">
      <slot name="footer-start" />
    </div>
    <div class="flex gap-12">
      <slot name="footer" />
    </div>
  </div>
</template>

<script>
import {
  defineComponent,
  inject
} from "vue";

export default defineComponent({
  name: "DialogWrapper",
  props: {
    title: {
      type: String,
      required: false
    },
    subheading: {
      type: String,
      required: false
    },
    modal: {
      type: Boolean,
      required: false,
      default: true
    }
  },
  emits: ["refetch"],
  setup() {
    const dialogRef = inject("dialogRef", {});

    return {
      dialogRef
    };
  }
});
</script>